<template>
  <div
    class="v-arora-input v-mb-sm"
    :class="{ disabled: disabled }"
    :inert="disabled"
  >
    <textarea
      :aria-label="label.toString()"
      :data-test-id="dataTestId"
      :enterkeyhint="enterkeyhint"
      :required="required"
      :rows="textareaRows"
      placeholder="&nbsp;"
      ref="textareaReference"
      v-model="input"
      @keyup.enter="emit('keyup.enter')"
    />
    <label class="v-arora-input__label">
      <slot name="label">
        <span
          class="v-pointer-events-none"
          v-html="label"
        />
      </slot>
      <span
        v-if="required"
        class="v-required"
      />
    </label>
  </div>
</template>

<script setup lang="ts">
import { useCommon, type VElement } from '@arora/common'

const {
  enterkeyhint = 'enter',
  required = false,
  text,
  textareaRows = 3
} = defineProps<
  VElement & {
    text: string[] | number | string | undefined
    textareaRows?: number
    enterkeyhint?: 'done' | 'enter' | 'go' | 'next' | 'previous' | 'search' | 'send'
  }
>()
const emit = defineEmits(['update:text', 'keyup.enter'])

const { stringIsNullOrWhitespace } = useCommon()

const input = computed<string[] | number | string | undefined>({
  get() {
    return stringIsNullOrWhitespace(text?.toString()) ? '' : text
  },
  set(value) {
    emit('update:text', value)
  }
})
const textareaReference = ref<HTMLTextAreaElement | undefined>()
watch(input, async () => {
  if (textareaReference.value) {
    const paddingTop = Number.parseInt(
      getComputedStyle(textareaReference.value).paddingTop
    )
    const paddingBottom = Number.parseInt(
      getComputedStyle(textareaReference.value).paddingBottom
    )
    const padding = paddingTop + paddingBottom
    const initialHeight =
      (Number.parseInt(getComputedStyle(textareaReference.value).height) - padding) /
      textareaReference.value.rows
    const scrollHeight = textareaReference.value.scrollHeight - padding
    textareaReference.value.rows = Math.ceil(scrollHeight / initialHeight)
  }
})
</script>
